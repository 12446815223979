import { createSlice } from "@reduxjs/toolkit";

export type CollectStateType = {
  userId: string;
  title: string;
  author: string;
};

export const collectSlice = createSlice({
  name: "collect",
  initialState: {
    userId: "",
    title: "",
    author: "",
  },
  reducers: {
    updateQueryParam: (state: CollectStateType, { payload }) => {
      // 设置username和nickname
      return payload;
    },
  },
});

export const { updateQueryParam } = collectSlice.actions;

export default collectSlice.reducer;
