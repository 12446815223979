import { useRequest, useTitle } from "ahooks";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Typography, Table, Image, Pagination, Button, Form, Input } from "antd";
import styles from "./History.module.scss";
import { DEFAULT_PAGE_SIZE, LIST_USER_ID_PARAM_KEY } from "../../constant";
import { getHistoryListService } from "../../services/history";
import moment from "moment";

const { Title } = Typography;

const History: FC = () => {
  const [searchParams] = useSearchParams();
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [queryParam, setQueryParam] = useState({
    userId: searchParams.get(LIST_USER_ID_PARAM_KEY) || "",
    title: "",
    authorName: "",
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [form] = Form.useForm();
  useEffect(() => {
    const { userId, title, authorName } = queryParam;
    form.setFieldsValue({
      userId,
      title,
      authorName,
    });
  }, [form]);

  const { run: query } = useRequest(
    async () => {
      const { userId, title, authorName, current, pageSize } = queryParam;
      if (!userId) return {};
      const data = await getHistoryListService({
        userId,
        title,
        authorName,
        pageNum: current,
        pageSize,
      });
      return data;
    },
    {
      refreshDeps: [queryParam],
      onSuccess(data) {
        const { list = [], total = 0 } = data;
        setTotal(total);
        setList(list);
      },
    }
  );

  const dataSource = list.map((item: any) => {
    const { bvid } = item;
    return {
      ...item,
      key: bvid,
    };
  });

  const tableColumns = [
    {
      title: "封面图",
      dataIndex: "cover",
      render: (cover: string, record: any) => {
        return <Image src={cover} width={200} />;
      },
      with: 200,
    },
    {
      title: "标题",
      dataIndex: "title",
      render: (title: string, record: any) => {
        return (
          <a href={`https://www.bilibili.com/video/${record.bvid}`} target="_blank">
            {title}
          </a>
        );
      },
    },
    {
      title: "作者",
      dataIndex: "authorName",
      width: 100,
      render: (authorName: string, record: any) => {
        return (
          <a href={`https://space.bilibili.com/${record.authorMid}`} target="_blank">
            {authorName}
          </a>
        );
      },
    },
    {
      title: "类型",
      dataIndex: "videos",
      width: 100,
      render: (videos: number, record: any) => {
        if (videos === 0) {
          return "直播";
        } else if (videos === 1) {
          return "视频";
        } else if (videos === 100) {
          return "合集";
        } else {
          return videos;
        }
      },
    },
    {
      title: "观看时间",
      dataIndex: "viewAt",
      width: 200,
      render: (viewAt: number, record: any) => {
        return moment(viewAt * 1000).format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ];

  function handlePageChange(page: number, pageSize: number) {
    setQueryParam({ ...queryParam, current: page, pageSize });
  }

  const onFinish = (values: any) => {
    const { userId, title, authorName } = values;
    setQueryParam({
      ...queryParam,
      userId,
      title,
      authorName,
      current: 1,
    });
    query();
  };

  return (
    <>
      <div className={styles.search}>
        <Form layout="inline" form={form} onFinish={onFinish}>
          <Form.Item
            label="用户ID"
            name="userId"
            rules={[{ required: true, message: "请输入用户名" }]}
          >
            <Input
              style={{ width: 250 }}
              className={styles.userId}
              placeholder="请输入用户ID(必填)"
            />
          </Form.Item>
          <Form.Item label="标题" name="title">
            <Input
              style={{ width: 200 }}
              className={styles.title}
              placeholder="请输入标题"
              allowClear
            />
          </Form.Item>
          <Form.Item label="作者" name="authorName">
            <Input
              style={{ width: 200 }}
              className={styles.author}
              placeholder="请输入作者"
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className={styles.content}>
        <Table dataSource={dataSource} columns={tableColumns} pagination={false}></Table>
      </div>
      <div className={styles.footer}>
        <Pagination
          current={queryParam.current}
          pageSize={queryParam.pageSize}
          total={total}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};
export default History;
