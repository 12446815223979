import React, { FC } from "react";
import { Typography } from "antd";
import axios from "axios";

const { Title } = Typography;

const Collect: FC = () => {
  return (
    <>
      <div>
        <Title>正在开发中</Title>
      </div>
    </>
  );
};
export default Collect;
