import axios, { ResDataType } from "./ajax";
type SearchOption = {
  userId: string;
  title: string;
  authorName: string;
  pageNum: number;
  pageSize: number;
};
export async function getHistoryListService(opt: Partial<SearchOption> = {}): Promise<ResDataType> {
  const url = "/mail/bilibili/history/query";
  const data = (await axios.get(url, { params: opt })) as ResDataType;
  return data;
}
