import React, { FC } from "react";
import { Button, Typography, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { MANAGE_PATHNAME } from "../router";
import styles from "./Home.module.scss";

const { Title, Paragraph, Link } = Typography;

const Home: FC = () => {
  const nav = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <Title level={3}>
          油猴脚本下载：
          <Link
            className={styles.link}
            href="https://greasyfork.org/zh-CN/scripts/493427-%E5%93%94%E5%93%A9%E5%93%94%E5%93%A9%E5%8E%86%E5%8F%B2%E8%BD%AC%E5%8F%91"
          >
            哔哩哔哩历史转发
          </Link>
        </Title>
        <div>
          <Button type="primary" onClick={() => nav(MANAGE_PATHNAME)}>
            开始使用
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Home;
