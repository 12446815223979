import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { Layout, Typography } from "antd";
import styles from "./MainLayout.module.scss";
import Logo from "../components/Logo";
import RightTop from "../components/RightTop";
const { Title } = Typography;

const { Header, Footer, Content } = Layout;

const MainLayout: FC = () => {
  return (
    <Layout>
      <Header className={styles.header}>
        <div className={styles.left}>
          <Logo />
        </div>
        <div className={styles.right}>
          <RightTop />
        </div>
      </Header>
      <Content className={styles.main}>
        <Outlet />
      </Content>
      <Footer className={styles.footer}>
        哔哩哔哩历史转发 &copy; {new Date().getFullYear()} Created by 理想是自由
      </Footer>
    </Layout>
  );
};
export default MainLayout;
