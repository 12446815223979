import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import ManageLayout from "../layouts/ManageLayout";
import History from "../pages/manage/History";
import Collect from "../pages/manage/Collect";
import Tutorial from "../pages/Tutorial";
import Feedback from "../pages/Feedback";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "manage",
        element: <ManageLayout />,
        children: [
          {
            path: "history",
            element: <History />,
          },
          {
            path: "collect",
            element: <Collect />,
          },
        ],
      },
      {
        path: "tutorial",
        element: <Tutorial />,
      },
      {
        path: "feedback",
        element: <Feedback />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default router;

export const HOME_PATHNAME = "/";
export const MANAGE_PATHNAME = "/manage";
export const TUTORIAL_PATHNAME = "/tutorial";
export const FEEDBACK_PATHNAME = "/feedback";
