import React, { FC } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Button, Space, message } from "antd";

import styles from "./ManageLayout.module.scss";
import { BarsOutlined, HistoryOutlined, StarOutlined } from "@ant-design/icons";
const ManageLayout: FC = () => {
  const nav = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Space direction="vertical">
          <Button
            type={pathname.startsWith("/manage/history") ? "default" : "text"}
            size="large"
            icon={<HistoryOutlined />}
            onClick={() => nav("/manage/history")}
          >
            历史
          </Button>
          <Button
            type={pathname.startsWith("/manage/collect") ? "default" : "text"}
            size="large"
            icon={<StarOutlined />}
            onClick={() => nav("/manage/collect")}
          >
            收藏
          </Button>
        </Space>
      </div>
      <div className={styles.right}>
        <Outlet />
      </div>
    </div>
  );
};
export default ManageLayout;
