import { Typography, Image } from "antd";
import React, { FC } from "react";
import styles from "./Feedback.module.scss";

const { Title, Paragraph, Link } = Typography;
const Feedback: FC = () => {
  return (
    <div className={styles.container}>
      <Title>有问题欢迎向我反馈</Title>
      <Title>微信: zflyoung(请备注小红书转发)</Title>
      <Title>邮箱: 799699348@qq.com</Title>
      <Image width={400} src={"https://cdn.mundane.ink/202402032206594.png"} />
    </div>
  );
};
export default Feedback;
