import { configureStore } from "@reduxjs/toolkit";
import collectReducer, { CollectStateType } from "./collectReducer";

export type StateType = {
  collect: CollectStateType;
};

export default configureStore({
  reducer: {
    collect: collectReducer,
  },
});
