import React, { FC } from "react";
import { Space, Typography } from "antd";
import { BilibiliOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./Logo.module.scss";
const { Title } = Typography;

const Logo: FC = () => {
  return (
    <div className={styles.container}>
      <Link to="/">
        <Space>
          <Title>
            <BilibiliOutlined />
          </Title>
          <Title>哔哩哔哩历史转发</Title>
        </Space>
      </Link>
    </div>
  );
};
export default Logo;
