import { Typography, Image } from "antd";
import React, { FC } from "react";
import styles from "./Tutorial.module.scss";

const { Title, Paragraph, Link } = Typography;
const Tutorial: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles["title-block"]}>
        <Title>1、打开chrome或者edge浏览器, 下载篡改猴插件</Title>
        <Image width={1200} src={"https://cdn.mundane.ink/202403272059036.png"} />
      </div>
      <div className={styles["title-block"]}>
        <Title>
          2、下载安装油猴脚本:
          <Link
            className={styles.link}
            href="https://greasyfork.org/zh-CN/scripts/493427-%E5%93%94%E5%93%A9%E5%93%94%E5%93%A9%E5%8E%86%E5%8F%B2%E8%BD%AC%E5%8F%91"
          >
            哔哩哔哩历史转发
          </Link>
        </Title>
      </div>
      <div className={styles["title-block"]}>
        <Title>3、在历史记录页面滚动浏览历史记录，历史数据将被转发到本站</Title>
      </div>
      <div className={styles["title-block"]}>
        <Title>4、点击右上角的“去历史管理页面”，链接直达</Title>
      </div>
      <div className={styles["title-block"]}>
        <Title>5、如果遇到问题</Title>
        <Title level={3}>服务正在完善中, 遇到意外问题可以刷新网页重试, 或者联系我</Title>
      </div>
    </div>
  );
};
export default Tutorial;
