/**
 * @description: 全局常量
 */

export const DEFAULT_PAGE_SIZE = 10;
export const LIST_USER_ID_PARAM_KEY = "userId";
export const LIST_DISPLAY_TITLE_PARAM_KEY = "displayTitle";
export const LIST_OWNER_NICKNAME_PARAM_KEY = "ownerNickname";
export const LIST_PAGE_NUM_PARAM_KEY = "pageNum";
export const LIST_PAGE_SIZE_PARAM_KEY = "pageSize";
export const STAT_PAGE_SIZE = 10; // 统计列表，默认的pageSize

export const STAT_COLORS = ["#FF2D2D", "#BE77FF", "#2894FF", "#00EC00", "#EAC100", "#FF9D6F"];
